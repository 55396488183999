import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable({
  providedIn: 'root'
})
export class CustomMatPaginatorIntlService extends MatPaginatorIntl {
  itemsPerPageLabel = 'Показувати:';
  nextPageLabel     = 'Наступна сторінка';
  previousPageLabel = 'Попередня сторінка';
  firstPageLabel    = 'Перша сторінка';
  lastPageLabel     = 'Остання сторінка';

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length == 0 || pageSize == 0) {
      return `0 of ${length}`;
    }
    
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? 
                    Math.min(startIndex + pageSize, length) : 
                    startIndex + pageSize;
    
    return `${startIndex + 1} – ${endIndex} з ${length}`;
  };

}
