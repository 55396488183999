import { Service } from '../../interfaces/service.interface'
import { ServicesApiService } from '../../api-service/services/services-api.service'

export class ListService {
  emptyStateIcon="services"
  emptyStateTitle="Послуги відсутні"
  emptyStateDescription="Ваш список послуг порожній, час його заповнити"
  showAvatar = true;

  constructor(
    private servicesApiService: ServicesApiService
  ) {}

  getShortCardTitle(item: Service): string {
    return item?.title;
  }

  getAvatar(item: Service): string {
    return item.image;
  }

  load(params) {
    return this.servicesApiService.getServices(params) as any;
  }
}
