import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, map, catchError, throwError } from 'rxjs'
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Helpers } from '../../entities/helpers';
import { AddAppointment, Appointment } from '../../interfaces/appointment.interface'

@Injectable({
  providedIn: 'root'
})
export class AppointmentsApiService {

  constructor(
    private http: HttpClient
  ) { }

  addAppointments(body: AddAppointment): Observable<Appointment> {
    const url = `${environment.apiEndpoint}/appointments`;

    return this.http.post<{data: Appointment}>(url, body).pipe(
      map((res) => res.data),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      })
    );
  }

  getAvailableTimeSlots(params: any): Observable<string[]> {
    const getParams = Helpers.prepareParams(params);
    const url = `${environment.apiEndpoint}/book_dates/times`;

    return this.http.get<{data: string[]}>(url, {params: getParams}).pipe(map(res => res.data));
  }

  getAvailableDates(params: any): Observable<string[]> {
    const getParams = Helpers.prepareParams(params);
    const url = `${environment.apiEndpoint}/book_dates/dates`;

    return this.http.get<{data: string[]}>(url, {params: getParams}).pipe(map(res => res.data));
  }
}
