<div class="content">
  <ng-container *ngIf="data !== null; else noData">
    <span class="title">{{data.first_name}} {{data.last_name}}</span>
    <span class="text">{{data.position?.title}}</span>
  </ng-container>

  <ng-template #noData>
    <span class="title">Виконавець <span *ngIf="formDataCardComponent.required" class="required">*</span></span>
    <span class="text">Оберіть співробітника</span>
  </ng-template>
</div>

