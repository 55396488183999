<div class="list-items-wrapper" [ngClass]="additionalClassWrapper" id="box">
  <div class="list search-input">
    <app-search-input
      *ngIf="searchVisible"
      [formControl]="searchControl"
      [placeholder]="searchPlaceholder"
      (onUpdate)="updateDataBySearchInput($event)"
    ></app-search-input>
  </div>

  <div class="list-container">
    <ng-container *ngIf="isLoaded$ | async">
      <app-empty-state
        *ngIf="isEmptyStateNoItems"
        [icon]="list.emptyStateIcon"
        [title]="list.emptyStateTitle"
        [description]="list.emptyStateDescription"
        [buttonTitle]="emptyStateButtonTitle"
        (onClickButton)="emptyStateButtonAction.emit()"
      ></app-empty-state>

      <app-empty-state
        *ngIf="isEmptyStateNoResults"
        icon="search"
        title="Нічого не знайдено"
        description="Спробуйте змінити параметри пошуку"
      ></app-empty-state>

      <ng-container *ngFor="let item of items; let index = index; let isLast = last">
        <app-short-info-card
          [title]="list.getShortCardTitle(item)"
          [firstName]="item.first_name"
          [lastName]="item.last_name"
          [description]="item.position?.title"
          [avatar]="list.getAvatar(item)"
          [showAvatar]="list.showAvatar"
          [iconClass]="iconClass"
          [price]="item.price"
          [duration]="item.duration"
          [isActive]="item.id == activeItemId"
          [isDivider]="!isLast"
          [isToggleClass]="hasActiveItem"
          (click)="onCardClickAction.emit(item)"
        >
          <ng-container
            dynamicContent
            [ngTemplateOutlet]="dynamicContent"
            [ngTemplateOutletContext]="{ item }"
          ></ng-container>
          <ng-container
            *ngIf="hasToggle"
            dynamicContent
            [ngTemplateOutlet]="toggle"
            [ngTemplateOutletContext]="{ item, index }"
          ></ng-container>
        </app-short-info-card>
      </ng-container>
    </ng-container>

    <ng-template #skeleton>
      <app-skeleton-info-card
        [timesToRepeat]="10"
      ></app-skeleton-info-card>
    </ng-template>
  </div>

  <app-paginator
    *ngIf="paginationVisible"
    class="list paginator"
    [itemsCount$]="totalCountItems"
    [pageSize]="pageSize"
    [currentPage]="currentPage"
    (onPageChange)="pageChanged($event)"
  ></app-paginator>
</div>

<ng-template #toggle let-item="item" let-index="index">
  <app-toggle-button
    [id]="item.id"
    [formControl]="togglesControl.controls[index]"
    [type]="typeOfToggle"
  ></app-toggle-button>
</ng-template>
