import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, State, StateContext } from '@ngxs/store'
import { UpdateFormValue } from '@ngxs/form-plugin';
import { APP_STATE_TOKEN, IAppState } from './app-state.interface'
import { AppActions } from './app-state.action'
import { BusinessArea, Company, IBranchesResponse } from '../interfaces/company.interface'
import { Observable, tap } from 'rxjs'
import { CompaniesApiService } from '../api-service/companies/companies-api.service'
import { Branch } from '../interfaces/branch.interface'
import { BranchesApiService } from '../api-service/branches/branches-api.service'

@State<IAppState>({
  name: APP_STATE_TOKEN,
  defaults: {
    newAppointmentForm: {
      model: {
        client: null,
        date: null,
        employee: null,
        services: [],
        totalPrice: null,
        totalDuration: null,
        description: null,
      }
    },
    company: null,
    branches: [],
    totalNumberOfBranches: null,
    businessAreas: [],
    activeBranch: null,
  }
})
@Injectable()
export class AppState implements NgxsOnInit {

  constructor(
    private companyApiService: CompaniesApiService,
    private branchApiService: BranchesApiService,
  ) {
  }

  ngxsOnInit(ctx: StateContext<IAppState>): void {}

  @Action(AppActions.UpdateAppointmentForm)
  updateAppointmentForm(
    ctx: StateContext<IAppState>,
    action: AppActions.UpdateAppointmentForm
  ): void {
    const formValue = ctx.getState().newAppointmentForm.model;

    ctx.dispatch(new UpdateFormValue({
      path: `AppState.newAppointmentForm`,
      value: {
        ...formValue,
        ...action.params
      },
    }));
  }

  @Action(AppActions.GetCompany)
  getCompany(
    ctx: StateContext<IAppState>,
    action: AppActions.GetCompany,
  ): Observable<Company> {

    return this.companyApiService.getCompany()
      .pipe(
        tap((res: Company) => {
          ctx.patchState({
            company: res,
          })
        }),
      )
  }

  @Action(AppActions.GetBusinessArea)
  getBusinessArea(
    ctx: StateContext<IAppState>,
    action: AppActions.GetBusinessArea,
  ): Observable<BusinessArea[]> {
    const { searchValue } = action.params

    return this.companyApiService.getBusinessArea(searchValue)
      .pipe(
        tap((res: BusinessArea[]) => {
          ctx.patchState({
            businessAreas: res,
          })
        }),
      )
  }

  @Action(AppActions.GetBranches)
  getBranches(
    ctx: StateContext<IAppState>,
    action: AppActions.GetBranches,
  ): Observable<IBranchesResponse> | void {
    const {
      branchId,
      search,
      limit,
      offset,
    } = action.params

    return this.companyApiService.getAllBranches({ branchId, search, limit, offset })
      .pipe(
        tap((res: IBranchesResponse) => {
          ctx.patchState({
            branches: res.data,
            totalNumberOfBranches: res.pagination.total,
          })
        }),
      )
  }

  @Action(AppActions.GetBranch)
  getBranch(
    ctx: StateContext<IAppState>,
    action: AppActions.GetBranch
  ): Observable<Branch> {
    const { branchId } = action.params;

    return this.branchApiService.getBranch(branchId)
      .pipe(
        tap((res: Branch) => {
          ctx.patchState({
            activeBranch: {...res}
          });
      })
    );
  }

  @Action(AppActions.GetBranchBySlug)
  getBranchBySlug(
    ctx: StateContext<IAppState>,
    action: AppActions.GetBranchBySlug
  ): Observable<Branch> {
    const { branchSlug } = action.params;

    return this.branchApiService.getBranchBySlug(branchSlug)
      .pipe(
        tap((res: Branch) => {
          ctx.patchState({
            activeBranch: {...res}
          });
      })
    );
  }
}
