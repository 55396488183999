import { HttpParams } from '@angular/common/http';
import { format } from 'date-fns';

export class Helpers {

  static prepareParams(obj: { [key: string]: any }): HttpParams {
    let params = new HttpParams();

    for (let prop in obj) {
      const value = obj[prop];

      if (Array.isArray(value)) {
        value.forEach(item => params = params.append(prop, item.toString()));
      } else if (value !== null && value !== undefined) {
        params = params.set(prop, value.toString());
      }
    }

    return params;
  }

  static formatDate(date: Date): string {
    return format(date, 'yyyy-MM-dd');
  }

  static copyToClipboard(text: string) {
    const el = document.createElement('textarea');
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  static generateUUID() {
    let d = new Date().getTime()
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      let r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  };
}
