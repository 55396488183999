import { Component, OnDestroy, OnInit } from '@angular/core'
import { AppStateSelectors } from '../../state/app-state.selector'
import { Router } from '@angular/router'
import { Select, Store } from '@ngxs/store'
import { AppointmentFormModel } from '../../interfaces/appointment.interface'
import { Branch } from '../../interfaces/branch.interface'
import { AppActions } from '../../state/app-state.action'
import { AmplitudeService } from '../../entities/amplitude.service'
import { Observable, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit, OnDestroy {
  @Select(AppStateSelectors.activeBranch) branch$: Observable<Branch>;

  formValue: AppointmentFormModel;
  companyId: string = null;
  branchId: string = null;
  branchSlug: string = null;
  onDestroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private store: Store,
    private amplitudeService: AmplitudeService,
  ) {
  }

  ngOnInit() {
    this.branch$.pipe(takeUntil(this.onDestroy$)).subscribe(branch => {
      this.companyId = branch.company_id;
      this.branchId = branch.id;
      this.branchSlug = branch.slug;

      this.formValue = this.store.selectSnapshot(AppStateSelectors.appointmentFormModel);

      this.onOpenErrorPage();
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onOpenErrorPage() {
    this.amplitudeService.trackEvent(
      'widget_error_shown',
      {
        company_id: this.companyId,
        branch_id: this.branchId,
        error_text: "На жаль сталася помилка",
        location: this.router.url,
      });
  }

  goToHomePage() {
    this.resetForm();
    this.router.navigate([this.branchSlug], {
      queryParamsHandling: 'merge',
    });
  }

  resetForm() {
    this.store.dispatch(new AppActions.UpdateAppointmentForm({
      client: null,
      date: null,
      employee: null,
      services: [],
      totalPrice: null,
      totalDuration: null,
    }));
  }
}
