import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { IEmployeesResponse } from '../../interfaces/employee.interface'
import { environment } from '@env/environment'
import { Helpers } from '../../entities/helpers'
import { Store } from '@ngxs/store'
import { AppStateSelectors } from '../../state/app-state.selector'

@Injectable({
  providedIn: 'root',
})
export class EmployeeApiService {

  constructor(
    private http: HttpClient,
    private store: Store,
  ) {
  }

  getEmployees(params: any): Observable<IEmployeesResponse> {
    let company_id = this.store.selectSnapshot(AppStateSelectors.activeBranch)?.company_id;
    if (!company_id) {
      throw new Error('Company ID is missing')
    }

    let getParams = Helpers.prepareParams({ company_id, ...params });
    const url = `${environment.apiEndpoint}/employees`;

    return this.http.get<IEmployeesResponse>(url, { params: getParams });
  }
}
