import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  transform(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    
    if (hours && !minutes) {
      return `${hours}год.`;
    } else if (!hours && minutes) {
      return `${minutes}хв.`
    } else {
      return `${hours}год. ${minutes}хв.`;
    }
  }

}
