import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Helpers } from '../../entities/helpers';
import { Store } from '@ngxs/store';
import { Category, ICategoriesResponse, IServicesResponse, ICategoriesParams } from '../../interfaces/service.interface';
import { AppStateSelectors } from '../../state/app-state.selector'

@Injectable({ providedIn: 'root' })
export class ServicesApiService {

  constructor(
    private http: HttpClient,
    private store: Store
  ) { }

  getServices(params: any): Observable<IServicesResponse> {
    let company_id = this.store.selectSnapshot(AppStateSelectors.activeBranch)?.company_id;
    if (!company_id) {
      throw new Error('Company ID is missing')
    }

    let getParams = Helpers.prepareParams({ company_id, ...params });
    const url = `${environment.apiEndpoint}/services`;

    return this.http.get<IServicesResponse>(url, { params: getParams });
  }

  getCategories(params: ICategoriesParams = null): Observable<Category[]> {
    let company_id = this.store.selectSnapshot(AppStateSelectors.activeBranch)?.company_id;
    let getParams = Helpers.prepareParams({company_id, ...params});
    const url = `${environment.apiEndpoint}/categories`;

    return this.http.get<ICategoriesResponse>(url, {
      params: getParams
    }).pipe(map((res) => res.data));
  }
}
