import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SkeletonBoxComponent } from './skeleton-box/skeleton-box.component';
import { SkeletonInfoCardComponent } from './skeleton-info-card/skeleton-info-card.component';
import { SkeletonCardDetailsComponent } from './skeleton-card-details/skeleton-card-details.component';
import { SkeletonAppointmentComponent } from './skeleton-appointment/skeleton-appointment.component';

const components = [
  SkeletonBoxComponent,
  SkeletonInfoCardComponent,
  SkeletonCardDetailsComponent,
  SkeletonAppointmentComponent
];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: components,
  exports: components
})
export class SkeletonModule { }
