import { Injectable } from '@angular/core';
import { Router, NavigationEnd, NavigationExtras } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private history: string[] = [];

  constructor(
    private router: Router,
    private location: Location
  ) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({ urlAfterRedirects }: any) => {
        this.history.push(urlAfterRedirects);
        if (this.history.length > 2) {
          this.history.shift();
        }
      });
  }

  navigateTo(route: string, params: any = {}, extras: NavigationExtras = {}): void {
    this.router.navigate([route, params], extras);
  }

  navigateWithQueryParam(route: string, queryParams: any = {}, extras: NavigationExtras = {}): void {
    this.router.navigate([route], { queryParams, ...extras });
  }

  goBack(defaultRoute = '/'): void {
    // if (this.history.length > 1) {
      this.location.back();
    // } else {
    //   this.router.navigateByUrl(defaultRoute);
    // }
  }

  goForward(): void {
    this.location.forward();
  }

}
