import { animate, style, transition, trigger } from '@angular/animations'
import { Component, Input } from '@angular/core'
import { CommonModule } from '@angular/common'


@Component({
  selector: 'app-animated-continue-button',
  templateUrl: './animated-continue-button.html',
  styleUrls: ['./animated-continue-button.scss'],
  standalone: true,
  imports: [
    CommonModule
  ],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ transform: 'translateY(100vh)', opacity: 0 }),
        animate('250ms ease-out', style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
      transition(':leave', [
        animate('250ms ease-in', style({ transform: 'translateY(100vh)', opacity: 0 })),
      ]),
    ]),
  ],
})
export class AnimatedContinueButton {
  @Input() titles: string[];
}
