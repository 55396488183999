import { Component, Input, OnInit } from '@angular/core';
import { DirectiveModule } from '../../directives/directive.module';
import { CommonModule } from '@angular/common';
import { AvatarComponent } from '../avatar/avatar.component';
import { PipesModule } from '../../pipes/pipes.module';

@Component({
  selector: 'app-short-info-card',
  templateUrl: './short-info-card.component.html',
  styleUrls: ['./short-info-card.component.scss'],
  imports: [
    CommonModule,
    DirectiveModule,
    AvatarComponent,
    PipesModule
  ],
  standalone: true,
})
export class ShortInfoCardComponent implements OnInit {
  @Input() title: string;
  @Input() firstName: string;
  @Input() lastName: string;
  @Input() address: string;
  @Input() duration: number;
  @Input() price: string;
  @Input() description: string;
  @Input() avatar: string = null;
  @Input() showAvatar: boolean = true;
  @Input() isActive: boolean = false;
  @Input() isDivider: boolean = false;
  @Input() isToggleClass: boolean = true;
  @Input() iconClass: string = null;

  constructor() { }

  ngOnInit() {
  }
}
