import { CompaniesApiService} from '../../api-service/companies/companies-api.service'
import { Branch } from '../../interfaces/branch.interface'

export class ListBranch {
  emptyStateIcon="ghost"
  emptyStateTitle="Філії відсутні"
  emptyStateDescription="Ваш список філій порожній, час його заповнити"
  showAvatar = true;

  constructor(
    private companyApiService: CompaniesApiService
  ) {}

  getShortCardTitle(item: Branch): string {
    return item?.title;
  }

  getAvatar(item: Branch): string {
    return item.logo;
  }

  load(params) {
    return this.companyApiService.getAllBranches(params) as any;
  }
}
