import { Component, OnDestroy, OnInit } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FORM_DATA_CARD } from '../../form-data-card.token'
import { IFormDataCard } from '../../form-data-card.interface'
import { FormDataCardComponent } from '../../form-data-card.component'
import { Router } from '@angular/router'
import { Select, Store } from '@ngxs/store'
import { Observable } from 'rxjs'
import { Branch } from '../../../../interfaces/branch.interface'
import { takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs/internal/Subject'
import { Path } from '../../../../app-routing.config'
import { AppActions } from '../../../../state/app-state.action'
import { AppStateSelectors } from '../../../../state/app-state.selector'

@Component({
  selector: 'app-employee-data-card',
  templateUrl: './employee-data-card.component.html',
  styleUrls: ['./employee-data-card.component.scss'],
  standalone: true,
  imports: [CommonModule],
  providers: [{
    provide: FORM_DATA_CARD,
    useExisting: EmployeeDataCardComponent,
  }],
})
export class EmployeeDataCardComponent implements OnInit, OnDestroy, IFormDataCard {
  @Select(AppStateSelectors.activeBranch) branch$: Observable<Branch>

  defaultIcon: string = 'icon-people'
  avatar: string
  data
  branchSlug: string
  onDestroy$ = new Subject<void>();

  constructor(
    public formDataCardComponent: FormDataCardComponent,
    private router: Router,
    private store: Store,
  ) {
  }

  ngOnInit() {
    this.branch$.pipe(takeUntil(this.onDestroy$)).subscribe(branch => {
      this.branchSlug = branch.slug;
    });
    this.getCurrentValue()
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getCurrentValue() {
    this.data = this.formDataCardComponent.control.value;
    this.avatar = this.data?.avatar;
  }

  removeItem() {
    this.store.dispatch(new AppActions.UpdateAppointmentForm({
      employee: null,
    }))
    this.getCurrentValue()
  }

  navigateTo() {
    this.router.navigate([this.branchSlug, Path.EMPLOYEE], {
      queryParamsHandling: 'merge',
    });
  }
}
