import { Component, ContentChild, OnInit, forwardRef, Injector, ChangeDetectionStrategy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FORM_DATA_CARD } from './form-data-card.token';
import { IFormDataCard } from './form-data-card.interface';
import { AvatarComponent } from '../avatar/avatar.component';
import { FormControlValueAccessorConnector } from '../form-control-value-accessor-connector';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-form-data-card',
  templateUrl: './form-data-card.component.html',
  styleUrls: ['./form-data-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    AvatarComponent
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormDataCardComponent),
      multi: true
    }
  ],
})
export class FormDataCardComponent extends FormControlValueAccessorConnector implements OnInit {
  @ContentChild(FORM_DATA_CARD as any, {static: true}) card: IFormDataCard;
  firstName;
  lastName;

  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.firstName = this.card?.firstName;
    this.lastName = this.card?.lastName;
  }

  removeItem(event: Event) {
    event.stopPropagation();
    this.card.removeItem();
  }

  navigateTo() {
    this.card.navigateTo();
  }

  isFormValueArray() {
    return Array.isArray(this.control.value);
  }

}
