<div
  class="short-card"
  [appToggleClass]="'selected'"
  [isToggle]="isToggleClass"
  [ngClass]="{
    selected: isActive,
    'no-hover': !isToggleClass
  }"
>
  <div class="content-wrapper">
    <div class="content">
      <app-avatar
        *ngIf="showAvatar"
        [firstName]="firstName"
        [lastName]="lastName"
        [image]="avatar"
        [iconClass]="iconClass"
      ></app-avatar>
      <div class="content-info">
        <div class="title" *ngIf="title">
          {{ title }}
        </div>
        <div class="additional-info">
          <span *ngIf="description">{{ description }}</span>
          <span *ngIf="address">{{ address }}</span>
          <span *ngIf="duration">{{ duration | duration }}</span>
          <span *ngIf="price">{{ price }} грн.</span>
        </div>
      </div>
    </div>
    <div class="dynamic-content">
      <ng-content select="[dynamicContent]"></ng-content>
    </div>
  </div>
  <div class="divider" [ngClass]="{ none: !isDivider }"></div>
</div>
