import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-skeleton-info-card',
  templateUrl: './skeleton-info-card.component.html',
  styleUrls: ['./skeleton-info-card.component.scss']
})
export class SkeletonInfoCardComponent implements OnInit {
  @Input() timesToRepeat: number;
 
  constructor() { }

  ngOnInit() {
  }

  numSequence(n: number): Array<number> {
    return Array(n);
  }

}
