<div class="content">
  <ng-container *ngIf="data !== null; else noData">
    <span class="title">{{data.date | date: 'd MMMM, EEEE' : '': 'uk'}}</span>
    <span class="text">{{data.timeSlot | timeTransform}}</span>
  </ng-container>

  <ng-template #noData>
    <span class="title">Дата та час <span *ngIf="formDataCardComponent.required" class="required">*</span></span>
    <span class="text">Оберіть вільну дату та час</span>
  </ng-template>
</div>
