<div class="main-content-container" *ngIf="branchId">
  <div class="header">
    <ng-container *ngIf="branch$ | async as branch">
      <div class="branch-name-wrapper">
        <app-avatar
          [image]="branch.company?.logo || branch.logo"
          iconClass="icon-map-pin"
          size="large"
        ></app-avatar>
        <div class="info">
          <div class="name">
            {{branch.title}}
          </div>
          <div *ngIf="branch.address">
            {{ branch.address }}
          </div>
        </div>
      </div>

      <div class="contacts-wrapper">
        <div class="label inform phone-number" *ngIf="branch.phone_number">
          <a href="tel:+38{{branch.phone_number}}">
            <i class="icon-call-phone"></i>
            {{ branch.phone_number | phoneTransform }}
          </a>
        </div>
        <div class="label inform" *ngIf="branch.site">
          <a href="{{ getSanitizedUrl(branch.site) }}" target="_blank">
            <i class="icon-globe"></i>
          </a>
        </div>
        <div class="label inform" *ngIf="branch.instagram">
          <a href="{{ getSanitizedUrl(branch.instagram) }}" target="_blank">
            <i class="icon-instagram"></i>
          </a>
        </div>
        <div class="label inform" *ngIf="branch.tiktok">
          <a href="{{ getSanitizedUrl(branch.tiktok) }}" target="_blank">
            <i class="icon-tiktok"></i>
          </a>
        </div>
        <div class="label inform" *ngIf="branch.facebook">
          <a href="{{ getSanitizedUrl(branch.facebook) }}" target="_blank">
            <i class="icon-facebook"></i>
          </a>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="divider"></div>

  <form
    [formGroup]="newAppointmentForm"
    ngxsForm="AppState.newAppointmentForm"
    (ngSubmit)="onSubmit()"
    class="form-container"
  >
    <app-form-data-card formControlName="services">
      <app-services-data-card></app-services-data-card>
    </app-form-data-card>

    <app-form-data-card formControlName="employee">
      <app-employee-data-card></app-employee-data-card>
    </app-form-data-card>

    <app-form-data-card formControlName="date">
      <app-date-data-card></app-date-data-card>
    </app-form-data-card>

    <ng-container *ngIf="showComments; else noComments">
      <app-input
        label="Коментар до запису"
        formControlName="description"
        [isTextarea]="true"
        [showActionButton]="true"
        actionButtonTitle="Видалити"
        (onActionButtonClick)="deleteComment()"
      ></app-input>
    </ng-container>

    <ng-template #noComments>
      <button class="outline -no-border" (click)="showComments = true">
        <i class="icon-plus"></i> Додати коментар
      </button>
    </ng-template>

    <div class="submit-button-wrapper">
      <button type="submit" class="primary-dark" [disabled]="newAppointmentForm.invalid">
        <span>Продовжити</span>
        <i class="icon-arrow-left" style="rotate: 180deg;"></i>
      </button>
    </div>
  </form>

  <footer>
    <span>Онлайн запис надано сервісом</span>
    <a href="https://natodi.com/" target="_blank">
      <div class="logo">
        <i class="icon-logo"></i>
        <i class="icon-logo-text"></i>
      </div>
    </a>
  </footer>
</div>
