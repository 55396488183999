import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appToggleClass]'
})
export class ToggleClassDirective {
  @Input('isToggle') isToggle: boolean = true;
  @Input('appToggleClass') className: string;
  private static activeElement: ElementRef;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  @HostListener('click')
  onClick() {
    // If this element is already active, do nothing
    if (ToggleClassDirective.activeElement === this.el || !this.isToggle) {
      return;
    }

    // If there's an active element with the class, remove the class from it
    if (ToggleClassDirective.activeElement) {
      this.renderer.removeClass(ToggleClassDirective.activeElement.nativeElement, this.className);
    }

    // Add the class to the clicked element and set it as active
    this.renderer.addClass(this.el.nativeElement, this.className);
    ToggleClassDirective.activeElement = this.el;
  }
}
