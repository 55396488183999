import { Selector } from '@ngxs/store';
import { LOADING_STATE_TOKEN } from './loading.state';
import { ILoadingState } from './loading.models';

export class LoadingSelectors {
  @Selector([LOADING_STATE_TOKEN])
  static isLoading(state: ILoadingState): boolean {
    return state.isLoading || false;
  }
}
