import { Component, OnInit } from '@angular/core'
import { Select, Store } from '@ngxs/store'
import { catchError, Observable, of } from 'rxjs'
import { Branch } from './interfaces/branch.interface'
import { AppStateSelectors } from './state/app-state.selector'
import { AmplitudeService } from './entities/amplitude.service'
import { AppActions } from './state/app-state.action'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { Path } from './app-routing.config'
import { Helpers } from './entities/helpers';
import { NavigationService } from './entities/navigation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'natodi-widget';

  @Select(AppStateSelectors.activeBranch) branch$: Observable<Branch>;
  branchError: boolean = false;
  userId: string;

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private amplitudeService: AmplitudeService,
    private navigationService: NavigationService,
  ) { }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      if (!params.has('branchSlug')) {
        return;
      }
      
      const branchSlug = params.get('branchSlug');
      this.initAmplitude();
      this.store.dispatch(new AppActions.GetBranchBySlug({ branchSlug: branchSlug }))
        .pipe(
          catchError(error => {
            if (error.status === 404) {
              this.branchError = true;
              this.router.navigate([Path.NOT_FOUND], {
                queryParamsHandling: 'merge',
              });
            }
            return of(null);
          })
        )
        .subscribe();
    });
  }

  initAmplitude() {
    this.userId = this.activatedRoute.snapshot.queryParamMap.get('userId');

    if (!this.userId) {
      this.userId = Helpers.generateUUID();
      this.updateUrl(this.userId);
    }

    this.amplitudeService.initAmplitude(this.userId);
  }

  updateUrl(id: string): void {
    const queryParams: Params = { userId: id };
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }

  goToMainPage() {
    window.open('https://natodi.com', '_self');
  }
}
