import { IGetBranchesParams } from '../interfaces/company.interface'

export namespace AppActions {
  export class UpdateAppointmentForm {
    static readonly type = '[App State] Update Appointment Form';

    constructor(public params: {
      [key: string]: any;
    }) {}
  }

  export class GetCompany {
    static readonly type = '[App State] Get Companies';
  }

  export class GetBusinessArea {
    static readonly type = '[App State] Get Business Areas';

    constructor(public params: {
      searchValue: string;
    }) {}
  }

  export class GetBranches {
    static readonly type = '[App State] Get Branches';

    constructor(public params: IGetBranchesParams) {}
  }

  export class GetBranch {
    static readonly type = '[App State] Get Branch By Id'

    constructor(public params: {
      branchId: string;
    }) {
    }
  }

  export class GetBranchBySlug {
    static readonly type = '[App State] Get Branch By Slug'

    constructor(public params: {
      branchSlug: string;
    }) {
    }
  }

}
