import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { IMyDate } from '@nodro7/angular-mydatepicker'
import { Store } from '@ngxs/store'
import { Service } from '../../interfaces/service.interface'

@Injectable({
  providedIn: 'root',
})
export class AppointmentsService {
  private bookedServices = new BehaviorSubject<any[]>([])

  bookedServices$ = this.bookedServices.asObservable()

  constructor(
    private store: Store,
  ) {
  }

  transformDateStringsToDateObjects(dateStrings: string[]): IMyDate[] {
    return dateStrings.map(dateStr => {
      const date = new Date(dateStr)
      return {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
      }
    })
  }

  getTotalDuration(services: Service[]): number {
    let duration = 0

    for (let item of services) {
      duration += item.duration
    }

    return duration
  }

  getTotalPrice(services: Service[]): number {
    let sum = 0

    for (let item of services) {
      sum += item.price
    }

    return sum
  }

}
