<div
  class="input-wrapper"
  [ngClass]="{
    'disabled': control.disabled,
    'errors': control.errors && control.dirty,
    'with-icon': icon
  }"
>
  <label
    *ngIf="label"
    [for]="inputId">
    {{label}} <span *ngIf="required">*</span>
  </label>
  <button *ngIf="showActionButton" (click)="onActionButtonClick.emit()" class="outline -no-border action-button">{{actionButtonTitle}}</button>
  <i *ngIf="icon" class="main-icon" [ngClass]="icon"></i>
  <i *ngIf="actionIcon" class="action-icon" [ngClass]="actionIcon"></i>
  <input
    *ngIf="!isTextarea"
    [attr.type]="type"
    [placeholder]="placeholder"
    [formControl]="control"
    [id]="inputId"
    [prefix]="isPhoneNum ? '+38 ' : ''"
    [mask]="isPhoneNum ? '(000) 000-0000' : '' "
    [ngClass]="{
      'with-icon': icon,
      'with-action-icon': actionIcon,
      'invalid': control.errors && control.dirty
    }">

  <textarea
    *ngIf="isTextarea"
    rows="4"
    cols="50"
    [placeholder]="placeholder"
    [formControl]="control"
    [id]="inputId"
    [ngClass]="{
      'with-icon': icon,
      'with-action-icon': actionIcon,
      'invalid': control.errors && control.dirty
    }"
  ></textarea>

  <div *ngIf="infoText" class="infoText">
    <i class="icon-info-outlined"></i>
    <span>{{infoText}}</span>
  </div>

  <div *ngIf="hasError()" class="infoText">
    <i class="icon-info-outlined"></i>
    <span>{{ errorMessage | translate : { label } }}</span>
  </div>
</div>
