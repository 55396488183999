<ng-container *ngIf="!branchError && (branch$ | async) as branch; else error">
  <router-outlet></router-outlet>
</ng-container>

<ng-template #loading>
  <app-is-loading-spinner></app-is-loading-spinner>
</ng-template>

<ng-template #error>
  <app-empty-state
    icon="ghost"
    title="404: Сторінка не знайдена"
    description="Сторінка видалена або ніколи не існувала"
    buttonTitle="Перейти на сайт Natodi"
    [hasButtonIcon]="false"
    (onClickButton)="goToMainPage()"
  ></app-empty-state>
</ng-template>
