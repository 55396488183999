<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="ToogleButtonShape.Circle">
    <div class="circle-checkbox">
      <input
        [id]="id"
        type="checkbox"
        [name]="id"
        [formControl]="control"
        [value]="isActive"
      />
      <label [for]="id">
        <div class="status-switch">
          <i [class]="control.value === false ? 'icon-plus' : 'icon-check'"></i>
        </div>
      </label>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="ToogleButtonShape.Input">
    <div class="checkbox-input-type-wrapper">
      <div *ngIf="label" class="checkbox-input-type-label">
        {{label}}
      </div>
      <div class="title-wrapper">
        <span class="title">{{control.value === false ? 'Не активний' : 'Активний'}}</span>
        <span class="sub-title">
          {{control.value === false ? 'Не доступно для запису' : 'Доступно для запису'}}
        </span>
      </div>
      <div class="custom-checkbox">
        <input
          [id]="id"
          type="checkbox"
          [name]="id"
          [formControl]="control"
          [value]="isActive"
        />
        <label [for]="id">
          <div class="status-switch"></div>
        </label>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <div class="custom-checkbox">
      <input
        [id]="id"
        type="checkbox"
        [name]="id"
        [formControl]="control"
        [value]="isActive"
      />
      <label [for]="id">
        <div class="status-switch"></div>
      </label>
    </div>
  </ng-container>
</ng-container>
