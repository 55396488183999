import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Select, Store } from '@ngxs/store'
import { CommonModule } from '@angular/common'
import { InputComponent } from '../../shared/input/input.component'
import { Client } from '../../interfaces/client.interface'
import { FormBaseComponent } from '../../entities/forms/edit-page-base.component'
import { ClientsApiService } from '../../api-service/clients/clients-api.service';
import { AppStateSelectors } from '../../state/app-state.selector'
import { Branch } from '../../interfaces/branch.interface'
import { AmplitudeService } from '../../entities/amplitude.service'
import { Observable } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Component({
  selector: 'app-client-form',
  templateUrl: './client-form.component.html',
  styleUrls: ['./client-form.component.scss'],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputComponent
  ],
  standalone: true
})
export class ClientFormComponent extends FormBaseComponent implements OnInit, OnDestroy {
  @Select(AppStateSelectors.activeBranch) branch$: Observable<Branch>;

  @Input() showTitle: boolean = true;
  @Output() onSubmitAction = new EventEmitter<string>();

  form: FormGroup = this.formBuilder.group({
    first_name: [null, [Validators.required]],
    last_name: [''],
    email: ['', Validators.email],
    phone_number: ['', [Validators.required]],
  });
  companyId: string = null;
  branchId: string = null;
  branchSlug: string = null;

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    protected toastr: ToastrService,
    private formBuilder: FormBuilder,
    private store: Store,
    private clientsApiService: ClientsApiService,
    private amplitudeService: AmplitudeService,
  ) {
    super(activatedRoute, router, toastr);
  }

  ngOnInit() {
    super.ngOnInit();

    this.branch$.pipe(takeUntil(this.onDestroy$)).subscribe(branch => {
      this.companyId = branch.company_id;
      this.branchId = branch.id;
      this.branchSlug = branch.slug;

      this.onOpenClientPage();
    });
  }

  loadData() {}

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  onOpenClientPage(): void {
    this.amplitudeService.trackEvent(
      'widget_new_client_form_opened',
      {
        company_id: this.companyId,
        branch_id: this.branchId,
      }
    )
  }

  onClientCreate(id: string): void {
    this.amplitudeService.trackEvent(
      'widget_client_created',
      {
        company_id: this.companyId,
        branch_id: this.branchId,
        client_id: id,
      }
    )
  }

  handleClientCreation(id: string) {
    this.formHasChange = false;
    this.onClientCreate(id);
    this.onSubmitAction.emit(id);
  }

  addInstance(): void {
    const body = {
      ...this.form.value,
      company_id: this.companyId
    }

    this.clientsApiService.addClient(body)
      .subscribe({
        next: (client: Client) => {
          this.handleClientCreation(client.id);
        },
        error: (data) => {
          this.handleClientCreation(data.error.detail.id);
        }
      });
  }
}
