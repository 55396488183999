import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Branch, IBranchResponse } from '../../interfaces/branch.interface'
import { map, Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class BranchesApiService {

  constructor(
    private http: HttpClient,
  ) { }

  getBranch(branchId: string): Observable<Branch> {
    const url = `${environment.apiEndpoint}/branches/${branchId}`;

    return this.http.get<IBranchResponse>(url).pipe(map((res) => res.data));
  }

  getBranchBySlug(branchSlug: string): Observable<Branch> {
    const url = `${environment.apiEndpoint}/branches/${branchSlug}/slug`;

    return this.http.get<IBranchResponse>(url).pipe(map((res) => res.data));
  }
}
