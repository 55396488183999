import { NgModule } from '@angular/core';
import { ToggleClassDirective } from './toggleClass.directive';
import { TabDirective } from './tab.directive';
import { CommonModule } from '@angular/common';

const directives = [
  ToggleClassDirective,
  TabDirective
];

@NgModule({
  imports: [CommonModule],
  declarations: directives,
  exports: directives
})
export class DirectiveModule { }
