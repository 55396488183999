import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ListItemsService {
  connectedItems = new BehaviorSubject<any[]>([]);
  connectedItems$ = this.connectedItems.asObservable();

  constructor() { }

}
