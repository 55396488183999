import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core'
import { CommonModule } from '@angular/common';
import { IFormDataCard } from '../../form-data-card.interface';
import { FORM_DATA_CARD } from '../../form-data-card.token';
import { FormDataCardComponent } from '../../form-data-card.component';
import { AvatarComponent } from '../../../avatar/avatar.component';
import { Router } from '@angular/router';
import { PipesModule } from '../../../../pipes/pipes.module';
import { Select, Store } from '@ngxs/store'
import { Observable } from 'rxjs'
import { Branch } from '../../../../interfaces/branch.interface'
import { Subject } from 'rxjs/internal/Subject'
import { takeUntil } from 'rxjs/operators'
import { Path } from '../../../../app-routing.config'
import { AppActions } from '../../../../state/app-state.action'
import { AppStateSelectors } from '../../../../state/app-state.selector'


@Component({
  selector: 'app-services-data-card',
  templateUrl: './services-data-card.component.html',
  styleUrls: ['./services-data-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    AvatarComponent,
    PipesModule
  ],
  providers: [{
    provide: FORM_DATA_CARD,
    useExisting: ServicesDataCardComponent
  }]
})
export class ServicesDataCardComponent implements OnInit, OnDestroy, IFormDataCard {
  @Select(AppStateSelectors.activeBranch) branch$: Observable<Branch>;

  defaultIcon: string = 'icon-star';
  avatar: string;
  data;
  branchSlug: string;
  onDestroy$ = new Subject<void>();

  get totalDuration(): number {
    let duration = 0;

    for (let item of this.formDataCardComponent.control.value) {
      duration += item.duration;
    }

    return duration;
  }

  get totalPrice(): number {
    let sum = 0;

    for (let item of this.formDataCardComponent.control.value) {
      sum += item.price;
    }

    return sum;
  }

  constructor(
    public formDataCardComponent: FormDataCardComponent,
    private router: Router,
    private store: Store
  ) { }

  ngOnInit() {
    this.branch$.pipe(takeUntil(this.onDestroy$)).subscribe(branch => {
      this.branchSlug = branch.slug;
    });
    this.getCurrentValue();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getCurrentValue() {
    this.data = this.formDataCardComponent.control.value;
    this.avatar = this.data?.image;
  }

  removeItem(event: Event, index: number) {
    event.stopPropagation();
    const copyServices = [...this.data];
    copyServices.splice(index, 1);
    this.store.dispatch(new AppActions.UpdateAppointmentForm({
      services: copyServices
    }));
    this.getCurrentValue();
  }

  navigateTo() {
    this.router.navigate([this.branchSlug, Path.SERVICES], {
      queryParamsHandling: 'merge',
    });
  }
}
