<div
  class="form-data-card"
  [ngClass]="{'summary': data?.length > 0}"
  (click)="navigateTo()">
  <app-avatar
    [iconClass]="defaultIcon"
  ></app-avatar>

  <div class="content">
    <ng-container *ngIf="data?.length > 0; else noData">
      <span class="title">Послуги <span *ngIf="formDataCardComponent.required" class="required">*</span></span>
      <span class="text">{{totalDuration | duration}} {{totalPrice}} грн.</span>
    </ng-container>

    <ng-template #noData>
      <span class="title">Послуги <span *ngIf="formDataCardComponent.required" class="required">*</span></span>
      <span class="text">Оберіть послуги для запису</span>
    </ng-template>
  </div>

  <div class="icon-wrapper no-data" *ngIf="data?.length === 0">
    <i class="icon-chevron-left"></i>
  </div>
  <div class="icon-wrapper" *ngIf="data?.length > 0">
    <i class="icon-pencil-create"></i>
  </div>
</div>

<ng-container *ngIf="data?.length > 0">
  <div class="form-data-card list-items" *ngFor="let item of data; let index = index">
    <ng-container *ngTemplateOutlet="serviceCard; context: { item: item, index: index }"></ng-container>
  </div>
</ng-container>

<ng-template #serviceCard let-data="item" let-index="index">
  <app-avatar
    [image]="data.image"
    [iconClass]="defaultIcon"
  ></app-avatar>

  <div class="content">
    <span class="title">{{data.title}}</span>
    <span class="text">{{data.duration | duration}} {{data.price}} грн.</span>
  </div>

  <div class="icon-wrapper remove" (click)="removeItem($event, index)">
    <i class="icon-trash-empty"></i>
  </div>
</ng-template>
