import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Client } from '../../interfaces/client.interface';

@Injectable({
  providedIn: "root"
})
export class ClientsApiService {

  constructor(
    private http: HttpClient,
  ) { }

  addClient(body: any): Observable<Client> {
    const url = `${environment.apiEndpoint}/clients`;
    return this.http.post<{data: Client}>(url, body).pipe(map((res) => res.data));
  }
}
