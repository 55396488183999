import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { Observable } from 'rxjs';
import { CustomMatPaginatorIntlService } from './custom-mat-paginator-intl.service';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatPaginatorModule
  ],
  providers: [
    { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntlService }
  ],
})
export class PaginatorComponent {
  @Input() itemsCount$: Observable<number>;
  @Input() pageSize: number;
  @Input() currentPage: number;
  @Output() onPageChange = new EventEmitter<void>();
}
