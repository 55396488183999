import { Component, forwardRef, Injector, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NG_VALUE_ACCESSOR, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormControlValueAccessorConnector } from '../form-control-value-accessor-connector';
import { Subject } from 'rxjs/internal/Subject';
import { ToggleButtonShape } from '../../entities/constant';

@Component({
  selector: 'app-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleButtonComponent),
      multi: true
    }
  ]
})
export class ToggleButtonComponent extends FormControlValueAccessorConnector {
  @Input() id: string;
  @Input() label: string;
  @Input() isActive: boolean = false;
  @Input() type: typeof ToggleButtonShape;

  ToogleButtonShape = ToggleButtonShape;
  onDestroy$ = new Subject<void>();

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
