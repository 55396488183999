export interface IPagination {
  limit: number;
  offset: number;
  total: number;
}

export interface IPaginationResponse {
  pagination: IPagination;
}

export enum EListType {
  EMPLOYEE = 'employee',
  SERVICE = 'service',
  CLIENT = 'client',
  BRANCH = 'branch'
}
