<ng-template #projectedContent>
  <ng-content></ng-content>
</ng-template>

<ng-container *ngIf="!isFormValueArray()">
  <div class="form-data-card" (click)="navigateTo()">
    <app-avatar
      [firstName]="control?.value?.first_name"
      [lastName]="control?.value?.last_name"
      [image]="card.avatar"
      [iconClass]="card.defaultIcon"
    ></app-avatar>

    <ng-container *ngTemplateOutlet="projectedContent" />

    <ng-container *ngIf="control?.value !== null; else noData">
      <div class="icon-wrapper" (click)="navigateTo()">
        <i class="icon-pencil-create"></i>
      </div>
    </ng-container>
    <ng-template #noData>
      <div class="icon-wrapper no-data">
        <i class="icon-chevron-left"></i>
      </div>
    </ng-template>
  </div>
</ng-container>

<ng-container *ngIf="isFormValueArray()">
  <ng-container *ngTemplateOutlet="projectedContent" />
</ng-container>
