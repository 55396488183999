import { Component, EventEmitter, Input, Output, AfterContentInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-week-view',
  templateUrl: './week-view.component.html',
  styleUrls: ['./week-view.component.scss'],
  standalone: true,
  imports: [
    CommonModule
  ]
})
export class WeekViewComponent implements AfterContentInit {
  @Input() selectedDate = new Date();
  @Input() enableDates;
  @Output() onDateChanged = new EventEmitter<Date>();

  currentDate = new Date();
  daysOfWeek: {day: Date, isEnableDates: boolean}[] = [];
  monthNames = [
    'Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень',
    'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'
  ];

  constructor() {}

  ngAfterContentInit() {
    this.setupWeekView();
  }

  setupWeekView() {
    const startOfWeek = this.getStartOfWeek(new Date(this.currentDate));
    this.daysOfWeek = this.generateWeek(startOfWeek);
  }

  getStartOfWeek(date: Date) {
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when week starts
    return new Date(date.setDate(diff));
  }

  generateWeek(startOfWeek: Date) {
    const days = Array.from({ length: 7 }, (_, index) => {
      const day = new Date(startOfWeek);
      day.setDate(day.getDate() + index);
      return {day, isEnableDates: this.findMatchingDate(day)};
    });

    return days;
  }

  findMatchingDate(dateString: Date): boolean {
    const inputDate = new Date(dateString);
    const inputDateWithoutTime = new Date(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate());

    function convertToDate(obj: { day: number, month: number, year: number }): Date {
      return new Date(obj.year, obj.month - 1, obj.day);
    }

    for (const obj of this.enableDates) {
      const objDate = convertToDate(obj);
      const objDateWithoutTime = new Date(objDate.getFullYear(), objDate.getMonth(), objDate.getDate());
      if (inputDateWithoutTime.getTime() === objDateWithoutTime.getTime()) {
        return true;
      }
    }

    return false;
  }

  changeWeek(offset: number) {
    this.currentDate.setDate(this.currentDate.getDate() + offset * 7);
    this.setupWeekView();
  }

  selectDate(day: Date) {
    this.selectedDate = day;
    this.onDateChanged.emit(day)
  }

  getCurrentMonth() {
    return this.monthNames[this.currentDate.getMonth()];
  }

  formatDay(date: Date) {
    return date.toLocaleDateString('uk', { weekday: 'short' });
  }

  formatDate(date: Date) {
    return date.getDate();
  }

  isToday(date: Date) {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  }

  isDatesSame(date1, date2) {
    return date1.getTime() === date2.getTime();
  }
}
