import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import {
  BusinessArea,
  Company,
  IBranchesResponse,
  IBusinessAreaResponse,
  ICompanyResponse,
} from '../../interfaces/company.interface'
import { map, Observable } from 'rxjs'
import { environment } from '@env/environment'
import { Helpers } from '../../entities/helpers'
import { Store } from '@ngxs/store'
import { AppStateSelectors } from '../../state/app-state.selector'

@Injectable({
  providedIn: 'root'
})
export class CompaniesApiService {

  constructor(
    private http: HttpClient,
    private store: Store,
  ) {
  }

  getAllBranches(value): Observable<IBranchesResponse> {
    let company_id = this.store.selectSnapshot(AppStateSelectors.company)?.id
    let params = Helpers.prepareParams({ company_id, ...value })
    const url = `${environment.apiEndpoint}/branches`

    return this.http.get<IBranchesResponse>(url, { params: params })
  }

  getCompany(): Observable<Company> {
    let company_id = this.store.selectSnapshot(AppStateSelectors.company)?.id
    const url = `${environment.apiEndpoint}/companies/${company_id}`

    return this.http.get<ICompanyResponse>(url).pipe(map((res) => res.data))
  }

  getBusinessArea(searchValue: string): Observable<BusinessArea[]> {
    let params = new HttpParams()
      .set('limit', 100)
      .set('search', searchValue)
    const url = `${environment.apiEndpoint}/business_areas`

    return this.http.get<IBusinessAreaResponse>(url, { params }).pipe(
      map((res) => {
        return res.data
      }),
    )
  }
}
