<div class="main-content-container" *ngIf="branchId">
  <div class="header">
    <button
      class="default"
      (click)="goToHomePage()">
      <i class="icon-arrow-left"></i>
    </button>

    <span class="title">Виконавець</span>
  </div>

  <app-list-items-wrapper
    [listType]="'employee'"
    [activeItemId]="activeEmployee?.id"
    [filters]="filters"
    [branchId]="branchId"
    searchPlaceholder="Введіть ім'я, прізвище або телефон"
    additionalClassWrapper="service-providers-wrapper"
    [hasActiveItem]="true"
    (onCardClickAction)="setEmployee($event)">
  </app-list-items-wrapper>

  <footer *ngIf="activeEmployee?.id">
    <div class="divider" ></div>

    <app-animated-continue-button
      [titles]="[activeEmployee.first_name + ' ' + activeEmployee.last_name]"
    >
      <div class="continue-block-btn-group__item" (click)="goToServiceSetUp()" *ngIf="formValue?.services.length === 0">
        <i class="icon-star"></i>
        <span>Послуги</span>
      </div>
      <div class="continue-block-btn-group__item" (click)="goToTimeSetUp()" *ngIf="formValue?.date === null">
        <i class="icon-calendar"></i>
        <span>Дата та час</span>
      </div>
      <div class="continue-block-btn-group__item" (click)="goToSummary()" *ngIf="formValue?.date !== null && formValue?.services.length > 0">
        <span>Продовжити</span>
        <i class="icon-arrow-left" style="rotate: 180deg"></i>
      </div>
    </app-animated-continue-button>
  </footer>
</div>
