<div class="main-content-container" *ngIf="branch?.id">
  <div class="main-content-wrapper">
    <div class="header">
      <i class="icon-check"></i>
      <span class="title">Ви успішно записалися!</span>
      <span class="subtitle">Онлайн-запис є рівнозначним запису за телефоном і не потребує підтвердження</span>
    </div>

    <div class="content-container">
      <div class="content">
        <div class="top-info">
          <div class="date-time">
            <span class="date">{{formValue.date?.date | date: 'dd MMMM, EEEE'}}</span>
            <span class="time">{{appointmentStartTime}} - {{appointmentEndTime}}</span>
          </div>

          <div class="employee">
            <app-avatar
              [firstName]="formValue.employee?.first_name"
              [lastName]="formValue.employee?.last_name"
              [image]="formValue.employee?.avatar"
              size="xs"
            ></app-avatar>
            <span>{{formValue.employee?.first_name}} {{formValue.employee?.last_name}}</span>
          </div>
        </div>

        <ul class="service-list">
          <li *ngFor="let service of formValue.services" class="service-list__details">
            <span class="title">{{service.title}}</span>
            <div class="info">
              <span class="duration">
                {{ service.duration | duration }}
              </span>
              <span class="price">
                {{ service.price }} грн
              </span>
            </div>
          </li>
        </ul>

        <span class="summary">Всього: {{formValue.totalPrice}} грн</span>
      </div>

      <div class="action-block">
        <div class="action-block__actions">
          <button class="outline" (click)="addToGoogleCalendar()">
            <i class="icon-calendar-dates"></i>
            Додати в календар
          </button>
          <button class="outline" (click)="openTelegramBot()">
            <i class="icon-telegram"></i>
            Telegram бот
          </button>
        </div>
        <button class="primary-dark" (click)="goToHomePage()">
          <i class="icon-refresh"></i>
          Записатись знову
        </button>
      </div>
    </div>
  </div>

  <footer>
    <span>Онлайн запис надано сервісом</span>
    <a href="https://natodi.com/" target="_blank">
      <div class="logo">
        <i class="icon-logo"></i>
        <i class="icon-logo-text"></i>
      </div>
    </a>
  </footer>
</div>
