<div class="main-content-container" *ngIf="branchId">
  <div class="header">
    <button
      class="default"
      (click)="goToHomePage()">
      <i class="icon-arrow-left"></i>
    </button>

    <span class="title">Контакти для запису</span>
  </div>

  <app-client-form
    [showTitle]="false"
    (onSubmitAction)="onSubmit($event)"
  ></app-client-form>
</div>
