<ng-container *ngFor="let item of numSequence(timesToRepeat)">
  <div class="short-card">
    <div class="content-wrapper">
      <div class="content">
        <app-skeleton-box class="avatar"></app-skeleton-box>
        <div class="content-info">
          <div class="title">
            <app-skeleton-box class="info"></app-skeleton-box>
          </div>
          <div class="description">
            <app-skeleton-box class="info"></app-skeleton-box>
          </div>
        </div>
      </div>
      <div class="dynamic-content">
        <app-skeleton-box class="info-icon"></app-skeleton-box>
      </div>
    </div>
    <div class="divider"></div>
  </div>
</ng-container>
