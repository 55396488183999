import { ChangeDetectorRef, Component, OnDestroy, OnInit, QueryList, TemplateRef, ViewChildren } from '@angular/core'
import { Router } from '@angular/router'
import { IAngularMyDpOptions, IMyDate, IMyDateModel } from '@nodro7/angular-mydatepicker'
import { DatePipe } from '@angular/common'
import { Observable } from 'rxjs/internal/Observable'
import { map, of, Subject } from 'rxjs'
import { Select, Store } from '@ngxs/store'
import { TabDirective } from '../../directives/tab.directive'
import { AppointmentsApiService } from '../../api-service/appointments/appointments-api.service'
import { AppointmentsService } from '../../api-service/appointments/appointments.service'
import { AppointmentDate, AppointmentFormModel } from '../../interfaces/appointment.interface'
import { Path } from '../../app-routing.config'
import { AppStateSelectors } from '../../state/app-state.selector'
import { AppActions } from '../../state/app-state.action'
import { Branch } from '../../interfaces/branch.interface'
import { AmplitudeService } from '../../entities/amplitude.service'
import { takeUntil } from 'rxjs/operators'


@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
})
export class DateComponent implements OnInit, OnDestroy {
  @Select(AppStateSelectors.activeBranch) branch$: Observable<Branch>;

  @ViewChildren(TabDirective) tabs: QueryList<TabDirective>;

  activeTabName: string;
  enableDates: any;
  currentTab: TemplateRef<any>;
  selectedDate: Date = new Date();
  myDatePickerOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd.mm.yyyy',
    disableUntil: {
      year: this.selectedDate.getFullYear() + 1,
      month: this.selectedDate.getMonth() + 1,
      day: this.selectedDate.getDate() - 1,
    },
    inline: true,
    stylesData: {
      styles: '',
      selector: 'natodi-datepicker'
    }
  };

  model: IMyDateModel = { isRange: false, singleDate: { jsDate: new Date() }, dateRange: null };
  times$: Observable<string[]> = of([]);
  selectedTime: string = null;
  formValue: AppointmentFormModel;
  companyId: string = null;
  branchId: string = null;
  branchSlug: string = null;
  onDestroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private appointmentsApiService: AppointmentsApiService,
    private datePipe: DatePipe,
    private appointmentsService: AppointmentsService,
    private store: Store,
    private cdr: ChangeDetectorRef,
    private amplitudeService: AmplitudeService,
  ) { }

  ngOnInit() {
    this.branch$.pipe(takeUntil(this.onDestroy$)).subscribe(branch => {
      this.companyId = branch.company_id;
      this.branchId = branch.id;
      this.branchSlug = branch.slug;

      this.formValue = this.store.selectSnapshot(AppStateSelectors.appointmentFormModel);

      this.getAvailableDays().subscribe((dates) => {
        this.enableDates = dates;
        this.myDatePickerOptions = {
          ...this.myDatePickerOptions,
          enableDates: dates
        };
        const formatedDate = this.datePipe.transform(this.selectedDate, 'yyyy-MM-dd');
        this.times$ = this.getAvailableTimeSlots(formatedDate);
      });

      this.onOpenDatePage();
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngAfterViewInit() {
    if (this.tabs && this.tabs.length > 0) {
      this.currentTab = this.tabs.first.template;
      this.activeTabName = this.tabs.first.name;
    }

    this.cdr.detectChanges();
  }

  onOpenDatePage() {
    this.amplitudeService.trackEvent(
      'widget_timepicker_opened',
      {
        company_id: this.companyId,
        branch_id: this.branchId,
      });
  }

  goToHomePage() {
    this.router.navigate([this.branchSlug], {
      queryParamsHandling: 'merge',
    });
  }

  getAvailableTimeSlots(bookDate: string): Observable<string[]> {
    const params = {
      branch_id: this.branchId,
      book_date: bookDate
    };

    const selectedEmployeeId = this.formValue?.employee?.id || null
    if (selectedEmployeeId) {
      params['employee_id'] = selectedEmployeeId
    }

    const selectedService = this.formValue?.services || [];
    const selectedServiceIds = selectedService.map(service => service.id);
    if (selectedServiceIds) {
      params['services'] = selectedServiceIds;
    }

    return this.appointmentsApiService.getAvailableTimeSlots(params);
  }

  getAvailableDays(): Observable<IMyDate[]> {
    const params = {
      branch_id: this.branchId
    };

    const selectedEmployeeId = this.formValue?.employee?.id || null
    if (selectedEmployeeId) {
      params['employee_id'] = selectedEmployeeId
    }

    return this.appointmentsApiService.getAvailableDates(params).pipe(
      map((dates) => this.appointmentsService.transformDateStringsToDateObjects(dates))
    );
  }

  onDateChanged(event: IMyDateModel): void {
    this.selectedDate = event.singleDate.jsDate;
    this.onSelectDateAction();
  }

  onSelectDateAction() {
    const formatedDate = this.datePipe.transform(this.selectedDate, 'yyyy-MM-dd');
    this.times$ = this.getAvailableTimeSlots(formatedDate);
    this.selectedTime = null;
  }

  selectDateWeekView(event) {
    this.selectedDate = event;
    this.onSelectDateAction();
  }

  selectDate() {
    if (this.selectedTime) {
      const params: AppointmentDate = {
        date: this.selectedDate,
        timeSlot: this.selectedTime
      };

      this.store.dispatch(new AppActions.UpdateAppointmentForm({
        date: params
      }));

      this.amplitudeService.trackEvent(
      'widget_time_selected',
      {
        company_id: this.companyId,
        branch_id: this.branchId,
        appointment_date: this.selectedDate,
        appointment_time: this.selectedTime,
      });
    }
  }

  goToServiceSetUp() {
    this.selectDate();
    this.router.navigate([this.branchSlug, Path.SERVICES], {
      queryParamsHandling: 'merge',
    });
  }

  goToEmployeeSetUp() {
    this.selectDate();
    this.router.navigate([this.branchSlug, Path.EMPLOYEE], {
      queryParamsHandling: 'merge',
    });
  }

  goToSummary() {
    this.selectDate();
    this.goToHomePage();
  }

}
