<div class="main-content-container" *ngIf="branchId">
  <div class="header">
    <button class="default" (click)="goToHomePage()">
      <i class="icon-arrow-left"></i>
    </button>

    <span class="title">Дата та час</span>

    <div class="tab-wrapper">
      <div
        *ngFor="let tab of tabs"
        class="label filter"
        (click)="currentTab = tab.template; activeTabName = tab.name"
        [ngClass]="activeTabName == tab.name ? 'selected' : ''">
        {{ tab.name }}
      </div>
    </div>
  </div>


  <!-- <div class="details_tabs tabs" *ngIf="tabs">
    <button
      *ngFor="let tab of tabs"
      (click)="currentTab = tab.template; activeTabName = tab.name"
      class="tab"
      [ngClass]="{'active': activeTabName == tab.name}">
      {{tab.name}}
    </button>
  </div> -->
  <!-- <div class="details_content"> -->
  <ng-container *ngTemplateOutlet="currentTab; context: { enableDates }"></ng-container>
  <!-- </div> -->



  <div class="selected-date">
    <span class="title">
      {{ selectedDate | date: 'd MMMM, EEEE' : '': 'uk' }}
    </span>

    <div class="time-picker-container" [ngStyle]="{'display': (times$ | async)?.length === 0 ? 'block' : 'flex'}">
      <div
        class="time-cell"
        *ngFor="let time of times$ | async"
        [ngClass]="{'selected': selectedTime === time}"
        (click)="selectedTime = time">
        {{ time | timeTransform}}
      </div>
      <ng-container *ngIf="(times$ | async)?.length === 0">
        <span class="empty-time">Не знайдено вільного часу</span>
      </ng-container>
    </div>
  </div>

  <footer *ngIf="selectedTime">
    <div class="divider" ></div>

    <app-animated-continue-button
      [titles]="[selectedTime | timeTransform]"
    >
      <div class="continue-block-btn-group__item" (click)="goToServiceSetUp()" *ngIf="formValue?.services.length === 0">
        <i class="icon-star"></i>
        <span>Послуги</span>
      </div>
      <div class="continue-block-btn-group__item" (click)="goToEmployeeSetUp()" *ngIf="formValue?.employee === null">
        <i class="icon-people"></i>
        <span>Виконавець</span>
      </div>
      <div class="continue-block-btn-group__item" (click)="goToSummary()" *ngIf="formValue?.employee !== null && formValue?.services.length > 0">
        <span>Продовжити</span>
        <i class="icon-arrow-left" style="rotate: 180deg"></i>
      </div>
    </app-animated-continue-button>
  </footer>
</div>

<div *tab="'Місяць'" class="month-view-container">
  <input
    angular-mydatepicker
    #dp="angular-mydatepicker"
    class="datePicker"
    type="hidden"
    locale="uk"
    [(ngModel)]="model"
    [options]="myDatePickerOptions"
    (dateChanged)="onDateChanged($event)"
  />
</div>

<div *tab="'Тиждень'" class="week-view-container">
  <app-week-view
    [enableDates]="enableDates"
    (onDateChanged)="selectDateWeekView($event)"
  ></app-week-view>
</div>

