import { Component, Input, OnInit, ElementRef, OnDestroy, EventEmitter, Output, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginatorComponent } from '../paginator/paginator.component';
import { map, takeUntil, tap } from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';
import { of, BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { AbstractControl, FormControl, FormArray } from '@angular/forms';
import { SearchInputComponent } from '../search-input/search-input.component';
import { DeviceDetectorService } from '../../entities/device-detector.service';
import { SkeletonModule } from '../skeleton/skeleton.module';
import { Subject } from 'rxjs/internal/Subject';
import { EmployeeApiService } from '../../api-service/employees/employees-api.service'
import { ShortInfoCardComponent } from '../short-info-card/short-info-card.component';
import { ListFactory } from '../../entities/list/list-factory';
import { EListType } from '../../entities/app.model';
import { ServicesApiService } from '../../api-service/services/services-api.service'
import { ClientsApiService } from '../../api-service/clients/clients-api.service'
import { ListItemsService } from '../../entities/list-items.service';
import { CompaniesApiService } from '../../api-service/companies/companies-api.service'
import { EmptyStateComponent } from "../empty-state/empty-state.component";
import { ToggleButtonComponent } from '../toggle-button/toggle-button.component';
import { PipesModule } from "../../pipes/pipes.module";

@Component({
  selector: 'app-list-items-wrapper',
  templateUrl: './list-items-wrapper.component.html',
  styleUrls: ['./list-items-wrapper.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    PaginatorComponent,
    SearchInputComponent,
    SkeletonModule,
    ShortInfoCardComponent,
    EmptyStateComponent,
    ToggleButtonComponent,
    PipesModule,
  ]
})
export class ListItemsWrapperComponent implements OnInit, OnDestroy {
  isLoaded = new BehaviorSubject<boolean>(false);
  @Input() items: any[] = null;
  @Input() paginationVisible: boolean = true;
  @Input() searchVisible: boolean = true;
  @Input() searchPlaceholder: string = 'Пошук ';
  @Input() hasActiveItem: boolean = true;
  @Input() hasToggle: boolean = false;
  @Input() additionalClassWrapper: string;
  @Input() listType: EListType;
  @Input() iconClass: string;
  @Input() activeItemId: string;
  @Input() isLoaded$: Observable<boolean> = this.isLoaded.asObservable();
  @Input() dynamicContent: TemplateRef<any>;
  @Input() emptyStateButtonTitle: string;
  @Input() branchId: string | number = 0;
  @Input() filters: any = null;
  @Input() typeOfToggle: boolean = null;
  @Output() emptyStateButtonAction = new EventEmitter<void>();
  @Output() onCardClickAction = new EventEmitter<string>();
  @Output() onToggleActivated = new EventEmitter<string>();
  @Output() onToggleDeactivated = new EventEmitter<string>();

  searchControl: AbstractControl = new FormControl();
  togglesControl: FormArray;
  currentPage = 0;
  pageSize: number = 10;
  totalCountItems: Observable<number>;
  isMobile: boolean = false;
  onDestroy$ = new Subject<void>();
  list;
  private actionActivationIdExecuted: boolean = false;
  private subscriptions: Subscription[] = [];
  isEmptyStateNoItems = true;
  isEmptyStateNoResults = false;

  constructor(
    private elRef: ElementRef,
    private deviceDetectorService: DeviceDetectorService,
    private employeeApiService: EmployeeApiService,
    private servicesApiService: ServicesApiService,
    private clientsApiService: ClientsApiService,
    private listItemsService: ListItemsService,
    private companyApiService: CompaniesApiService,
  ) {
  }

  ngOnInit() {
    this.deviceDetectorService.isMobile()
      .pipe(
        takeUntil(this.onDestroy$),
      ).subscribe(val => {
        this.isMobile = val;
      });

    const providerApi = this.getProviderApi(this.listType);
    this.list = ListFactory.createList(this.listType, providerApi);

    if (!this.items) {
      this.load();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getProviderApi(type: EListType) {
    switch (type) {
      case EListType.EMPLOYEE:
        return this.employeeApiService;
      case EListType.SERVICE:
        return this.servicesApiService;
      case EListType.BRANCH:
        return this.companyApiService;
    }
  }

  prepareParams(search?: string) {
    return {
      ...this.filters,
      branch_id: this.branchId,
      limit: this.pageSize,
      offset: this.pageSize * this.currentPage,
      ...(search && { search }),
    };
  }

  scrollTop() {
    scrollTo({ left: 0, top: this.elRef.nativeElement?.offsetTop, behavior: 'smooth' });
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.branchId = this.branchId == 0 ? null : this.branchId as string;
    this.currentPage = event.pageIndex;
    this.load();
  }

  updateDataBySearchInput(search: string) {
    this.load(search);

    this.isEmptyStateNoItems = false;
    this.isEmptyStateNoResults = true;
  }

  setActiveItem(result) {
    if (result?.data?.length > 0) {
      this.activeItemId = result?.data[0].id;
      this.onCardClickAction.emit(result?.data[0]);
      this.actionActivationIdExecuted = true;
    } else {
      this.activeItemId = null;
    }
  }

  subscribeToChanges() {
    this.togglesControl.controls.forEach((control, index) => {
      const subscription = control.valueChanges.subscribe(value => {
        this.handleToggleChange(value, index);
      });
      this.subscriptions.push(subscription);
    });
  }

  handleToggleChange(value: boolean, index: number) {
    if (value) {
      this.onToggleActivated.emit(this.items[index]);
    } else {
      this.onToggleDeactivated.emit(this.items[index]);
    }
  }

  prepareToggleForm(list, connectedItems) {
    return list.data.map(item => {
      let index = connectedItems.findIndex(el => el.id === item.id);
      return new FormControl(index !== -1);
    });
  }

  load(search?: string) {
    this.scrollTop();
    this.isLoaded.next(false);  // Indicate loading has started

    const params = this.prepareParams(search);

    combineLatest({
      list: this.list.load(params),
      connectedItems: this.listItemsService.connectedItems$,
    }).pipe(
      map(({ list, connectedItems }: any) => {
        this.togglesControl = new FormArray(this.prepareToggleForm(list, connectedItems));

        if (this.hasToggle) {
          this.subscribeToChanges();
        }

        return list;
      }),
      tap((result) => {
        if (!this.actionActivationIdExecuted && !this.isMobile && this.hasActiveItem) {
          this.setActiveItem(result);
        }

        this.isEmptyStateNoResults = !!search && result.data.length === 0;
        this.isEmptyStateNoItems = !search && result.data.length === 0;
      }),
      takeUntil(this.onDestroy$)
    ).subscribe((result) => {
      this.items = result.data as any[];
      this.totalCountItems = of(result.pagination.total);
      this.isLoaded.next(true);
    });
  }
}
