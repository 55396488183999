<div class="week-view">
  <div class="week-view-header">
    <span class="month-name">{{ getCurrentMonth() }}</span>
    <div class="button-wrapper">
      <button (click)="changeWeek(-1)" class="week-nav prev">
        <i class="icon-chevron-left"></i>
      </button>
      <button (click)="changeWeek(1)" class="week-nav next">
        <i class="icon-chevron-right"></i>
      </button>
    </div>
  </div>

  <div class="days">
    <div
      *ngFor="let item of daysOfWeek"
      class="day"
      [class.today]="isToday(item.day)"
      (click)="selectDate(item.day)"
      [ngClass]="{
        selected: isDatesSame(selectedDate, item.day),
        'is-enable-dates': item.isEnableDates
      }"
    >
      <span class="day-of-week">{{ formatDay(item.day) }}</span>
      <span class="date">{{ formatDate(item.day) }}</span>
    </div>
  </div>
</div>
