<div class="main-content-container" *ngIf="branchId">
  <div class="header">
    <button
      class="default"
      (click)="goToHomePage()">
      <i class="icon-arrow-left"></i>
    </button>

    <span class="title">Оберіть послуги</span>
  </div>

  <app-list-items-wrapper
    [listType]="'service'"
    iconClass="icon-star"
    [filters]="filters"
    [branchId]="branchId"
    [activeItemId]="null"
    searchPlaceholder="Введіть назву послуги"
    [typeOfToggle]="ToggleButtonShape.Circle"
    additionalClassWrapper="service-providers-wrapper"
    [hasActiveItem]="false"
    [hasToggle]="true"
    (onToggleActivated)="addService($event); isSelected = true"
    (onToggleDeactivated)="removeService($event); isSelected = true"
  ></app-list-items-wrapper>

<!--  <ng-template #dynamicContent let-item="item">-->
<!--    <app-toggle-button-->
<!--      [id]="item.id"-->
<!--      [type]="ToggleButtonShape.Circle"-->
<!--      [isActive]="isItemAdded(item)"-->
<!--      (onToggle)="onToggle($event, item)"-->
<!--      ></app-toggle-button>-->
<!--  </ng-template>-->

  <footer *ngIf="selectedServices.length > 0">
    <div class="divider" ></div>

    <app-animated-continue-button
      [titles]="['Послуги: ' + selectedServices.length, getSelectedServicesDuration() | duration, getSelectedServicesPrice() + ' грн']"
    >
      <div class="continue-block-btn-group__item" (click)="goToEmployeeSetUp()" *ngIf="formValue?.employee === null">
        <i class="icon-people"></i>
        <span>Виконавець</span>
      </div>
      <div class="continue-block-btn-group__item" (click)="goToTimeSetUp()" *ngIf="formValue?.date === null">
        <i class="icon-calendar"></i>
        <span>Дата та час</span>
      </div>
      <div class="continue-block-btn-group__item" (click)="goToSummary()" *ngIf="formValue?.date !== null && formValue?.employee !== null">
        <span>Продовжити</span>
        <i class="icon-arrow-left" style="rotate: 180deg"></i>
      </div>
    </app-animated-continue-button>
  </footer>
</div>


