import { Component, OnDestroy, OnInit } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { FormBuilder, Validators } from '@angular/forms'
import { Select } from '@ngxs/store'
import { Router } from '@angular/router'
import { Branch } from '../../interfaces/branch.interface'
import { Path } from '../../app-routing.config'
import { AppStateSelectors } from '../../state/app-state.selector'
import { AmplitudeService } from '../../entities/amplitude.service'
import { takeUntil } from 'rxjs/operators'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  @Select(AppStateSelectors.activeBranch) branch$: Observable<Branch>;

  newAppointmentForm = this.fb.group({
    employee: [null, [Validators.required]],
    date: [null, [Validators.required]],
    totalPrice: [null, [Validators.required]],
    totalDuration: [null, [Validators.required]],
    services: [[], [Validators.required]],
    description: [null]
  });
  onDestroy$ = new Subject<void>();
  showComments: boolean = false;
  companyId: string = null;
  branchId: string = null;
  branchSlug: string = null;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private amplitudeService: AmplitudeService,
  ) { }

  ngOnInit() {
    this.branch$.pipe(takeUntil(this.onDestroy$)).subscribe(branch => {
      this.companyId = branch.company_id;
      this.branchId = branch.id;
      this.branchSlug = branch.slug;

      this.onOpenAppointmentCreation();
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onOpenAppointmentCreation() : void {
    this.amplitudeService.trackEvent(
      'widget_appointment_creation_started',
      {
        company_id: this.companyId,
        branch_id: this.branchId,
      });
  }

  goToClientSetUp() {
    this.router.navigate([this.branchSlug, Path.CONFIRMATION], {
      queryParamsHandling: 'merge',
    });
  }

  deleteComment() {
    this.showComments = false;
    this.newAppointmentForm.patchValue({
      description: null
    });
  }

  onSubmit() {
    if (this.newAppointmentForm.valid) {
      this.goToClientSetUp();
    }
  }

  getSanitizedUrl(url: string) {
    if (!url.startsWith('http')) {
      return 'https://' + url;
    }
    return url;
  }
}
