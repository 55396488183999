<div class="main-content-container" *ngIf="branchId">
  <div class="main-content-wrapper">
    <app-empty-state
      icon="tools-light"
      title="На жаль сталася помилка"
      description="Спробуйте ще раз..."
      buttonTitle="На головну"
      [hasButtonIcon]="false"
      (onClickButton)="goToHomePage()"
    >
    </app-empty-state>
  </div>

  <footer>
    <span>Онлайн запис надано сервісом</span>
    <a href="https://natodi.com/" target="_blank">
      <div class="logo">
        <i class="icon-logo"></i>
        <i class="icon-logo-text"></i>
      </div>
    </a>
  </footer>
</div>
