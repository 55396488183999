import { Component, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { Select, Store } from '@ngxs/store'
import { AppointmentFormModel } from '../../interfaces/appointment.interface'
import { Branch } from '../../interfaces/branch.interface'
import { DatePipe } from '@angular/common'
import { AppStateSelectors } from '../../state/app-state.selector'
import { AppActions } from '../../state/app-state.action'
import { AmplitudeService } from '../../entities/amplitude.service'
import { Observable } from 'rxjs'
import { Path } from '../../app-routing.config'
import { takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs/internal/Subject'


@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss'],
})
export class SuccessComponent implements OnInit, OnDestroy {
  @Select(AppStateSelectors.activeBranch) branch$: Observable<Branch>;

  formValue: AppointmentFormModel;
  branch: Branch;
  appointmentStartTime: string;
  appointmentEndTime: string;
  onDestroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private store: Store,
    private datePipe: DatePipe,
    private amplitudeService: AmplitudeService,
  ) {
  }

  ngOnInit() {
    this.branch$.pipe(takeUntil(this.onDestroy$)).subscribe(branch => {
      this.branch = branch;

      this.formValue = this.store.selectSnapshot(AppStateSelectors.appointmentFormModel);
      if (!this.formValue.employee || !this.formValue.services || !this.formValue.date) {
        this.router.navigate([this.branch.slug, Path.ERROR], {
          queryParamsHandling: 'merge',
        });
      }
      this.appointmentStartTime = this.formValue.date.timeSlot.split(':').slice(0, 2).join(':');
      this.appointmentEndTime = this.calculateEndTime(this.formValue.date.timeSlot, this.formValue.totalDuration);
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  goToHomePage() {
    this.resetForm();

    this.amplitudeService.trackEvent(
    'widget_new_appointment_clicked',
    {
      company_id: this.branch.company_id,
      branch_id: this.branch.id,
    });

    this.router.navigate([this.branch.slug], {
      queryParamsHandling: 'merge',
    });
  }

  calculateEndTime(startTime: string, duration: number): string {
    const [hours, minutes] = startTime.split(':').map(Number);
    let totalMinutes = hours * 60 + minutes + Math.floor(duration / 60);
    const endHours = Math.floor(totalMinutes / 60) % 24; // Hours wrap around after 24
    const endMinutes = totalMinutes % 60;
    return `${endHours.toString().padStart(2, '0')}:${endMinutes.toString().padStart(2, '0')}`;
  }

  addToGoogleCalendar() {
    const servicesTitles = this.formValue.services.map(service => service.title).join(', ')
    const employeeName = `${this.formValue.employee.first_name} ${this.formValue.employee.last_name}`
    const title = `${this.branch.title}: ${servicesTitles} до ${employeeName}`

    const description = [
      `Послуги: ${servicesTitles}`,
      `Спеціаліст: ${employeeName}`,
      '',
      'Ми з нетерпінням чекаємо зустрічі з вами!',
      'Дякуємо за вибір Natodi!',
    ].join('\n')

    const [startHours, startMinutes] = this.appointmentStartTime.split(':');
    const [endHours, endMinutes] = this.appointmentEndTime.split(':');

    const googleDate = this.datePipe.transform(this.formValue.date.date, 'yyyyMMdd');
    const date = `${googleDate}T${startHours}${startMinutes}00/${googleDate}T${endHours}${endMinutes}00`;

    const eventParams = new URLSearchParams({
      action: 'TEMPLATE',
      text: title,
      dates: date,
      details: description,
      location: this.branch.address,
    });

    const baseUrl = 'https://www.google.com/calendar/render';

    this.amplitudeService.trackEvent(
      'widget_add_to_calendar_clicked',
      {
        company_id: this.branch.company_id,
        branch_id: this.branch.id,
      });
    window.open(baseUrl + '?' + eventParams.toString(), '_blank');
  }

  openTelegramBot() {
    const botUrl = 'https://t.me/natodi_client_bot';
    this.amplitudeService.trackEvent(
      'widget_telegram_bot_clicked',
      {
        company_id: this.branch.company_id,
        branch_id: this.branch.id,
        bot_url: botUrl,
      });
    window.open(botUrl, '_blank');
  }

  resetForm() {
    this.store.dispatch(new AppActions.UpdateAppointmentForm({
      client: null,
      date: null,
      employee: null,
      services: [],
      totalPrice: null,
      totalDuration: null
    }));
  }

}
