import { EListType } from "../app.model";
import { ListEmployee } from "./list-employee";
import { ListService } from './list-service';
import { ListBranch } from './list-branch';

export class ListFactory {

  public static createList(listType: EListType, provider: any): any {
    switch (listType) {
      case EListType.EMPLOYEE:
        return new ListEmployee(provider);
      case EListType.SERVICE:
        return new ListService(provider);
      case EListType.BRANCH:
        return new ListBranch(provider);
    }
  }
}
