import { EmployeeApiService } from '../../api-service/employees/employees-api.service'
import { Employee } from '../../interfaces/employee.interface'


export class ListEmployee {
  emptyStateIcon="clients-simple"
  emptyStateTitle="Виконавці відсутні"
  emptyStateDescription=""
  showAvatar = true;

  constructor(
    private employeeApiService: EmployeeApiService
  ) {}

  getShortCardTitle(item: Employee): string {
    return item?.first_name + ' ' + item?.last_name;
  }

  getAvatar(item: Employee): string {
    return item.avatar;
  }

  load(params) {
    return this.employeeApiService.getEmployees(params) as any;
  }
}
